import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from '@sentry/tracing'; // Must import second
import { ExtraErrorData, Offline } from '@sentry/integrations';

const localTarget = 'localhost';
const regexGroup = /.*\/\/(.*)\/.*/;
const envTarget =
  process.env.GATSBY_ACTIVE_ENV === 'local'
    ? localTarget
    : process.env.GATSBY_ACTIVE_ENV.replace(regexGroup, '$1');

Sentry.init({
  release: process.env.SENTRY_RELEASE,
  environment: process.env.SENTRY_ENV,
  dsn: process.env.SENTRY_DSN,
  debug: false,
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.01 : 0, // Adjust this value in production
  // @see https://docs.sentry.io/platforms/javascript/configuration/options/#normalize-depth
  normalizeDepth: 21,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [envTarget, /^\//],
      shouldCreateSpanForRequest: url => !url.match(/\/health\/?$/)
    }),
    new ExtraErrorData({
      // @see https://docs.sentry.io/platforms/javascript/guides/gatsby/configuration/integrations/plugin/#extraerrordata
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      // When changing this value, make sure to update `normalizeDepth` of the whole SDK
      // to `depth + 1` in order to get it serialized properly - https://docs.sentry.io/platforms/javascript/configuration/options/#normalize-depth
      depth: 20
    }),
    new Offline()
  ]
});
